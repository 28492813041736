<template>
    <div class="branch_wpr condition-container" v-if="action.action_type == 'condition'">
        <div class="branch-add-action" v-if="actionsLength == 0">
            <div class="step_type">
                <button class="add_new 001" @click.stop="addAction($event)"><i class="fas fa-plus"></i></button>
                <div class="add_types">
                    <h3>Add a next step to your Journey</h3>
                    <ul>
                        <li>
                            <div class="card_item" @click.stop="handleAddAction(index, 'delay', action.parent_id ? action.parent_id : action.id, 0, allActions)">
                                <i class="far fa-clock"></i>
                                <h4>Add Delay</h4>
                            </div>
                        </li>
                        <li>
                            <div class="card_item" @click.stop="handleAddAction(index, 'condition', action.parent_id ? action.parent_id : action.id, 0, allActions)">
                                <i class="fas fa-code-branch"></i>
                                <h4>Add Condition</h4>
                            </div>
                        </li>
                        <li>
                            <div class="card_item" @click.stop="handleAddAction(index, 'action', action.parent_id ? action.parent_id : action.id, 0, allActions)">
                                <i class="fas fa-cog"></i>
                                <h4>Add Action</h4>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="branch_card" :id="`action-box-${action.id}`" :class="{ 'active' : selectedConditionIndex === action.id }" @click="selectAction(action.id, 'condition', action)">
            <span><i class="fas fa-code-branch"></i></span>
            <div class="branch_info">
                <h4 v-html="conditionTitle(action.condition)" :class="{ 'border-0 p-0': conditionTitle(action.condition) !== 'Set Condition' }"></h4>
                <button type="button" class="remove_btn" @click.stop="handleDeleteAction(action, 'condition')"><i class="fas fa-trash-alt"></i></button>
            </div>
        </div>
        <ul>
            <li>
                  <div class="icons"><i class="fas fa-thumbs-up" style="background: #2f7fed;"></i></div>
                  <draggable v-model="trueActions" :disabled="true" item-key="id" tag="ul" class="journey_list" :animation="200" ghost-class="ghost-card" :group="draggableGroup" :class="{ 'pb-0' : trueActions.length }">
                      <template #item="{element, index: trueIndex}">
                          <span>
                              <div class="delay-action-add" v-if="trueIndex == 0 && element.action_type != 'condition'">
                                  <div class="step_type" v-if="element.action_type != 'condition'">
                                      <button class="add_new 004" @click.stop="addAction($event)">
                                          <i class="fas fa-plus"></i>
                                      </button>
                                      <div class="add_types">
                                          <h3>Add a next step to your Journey</h3>
                                          <ul>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(trueIndex, 'delay', action.id, 1, trueActions)">
                                                      <i class="far fa-clock"></i>
                                                      <h4>Add Delay</h4>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(trueIndex, 'condition', action.id, 1, trueActions)">
                                                      <i class="fas fa-code-branch"></i>
                                                      <h4>Add Condition</h4>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(trueIndex, 'action', action.id, 1, trueActions)">
                                                      <i class="fas fa-cog"></i>
                                                      <h4>Add Action</h4>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                              <li :class="{ branch: element.action_type == 'condition', 'incomplete-action': element.is_finished == 0 }" :id="`step-${element.id}`">
                                  <div class="step_info timer" :id="`action-box-${element.id}`" :class="{ 'active' : selectedDelayIndex === element.id }" @click="selectAction(element.id, 'delay', element)" v-if="element.action_type == 'delay'">
                                      <i class="fas fa-clock"></i>
                                      <h4 v-html="delayTitle(element.delay)"></h4>
                                      <button type="button" class="remove_btn" @click.stop="handleDeleteAction(element, 'delay')"><i class="fas fa-trash-alt"></i></button>
                                  </div>
                                  <condition-component
                                      :action="element"
                                      :delay-title="delayTitle"
                                      :delete-action="deleteAction"
                                      :select-action="selectAction"
                                      :action-Title="actionTitle"
                                      :action-name="actionName"
                                      :add-action="addAction"
                                      :add-new-action="addNewAction"
                                      :selected-trigger-index="selectedTriggerIndex"
                                      :selected-action-index="selectedActionIndex"
                                      :selected-delay-index="selectedDelayIndex"
                                      :selected-condition-index="selectedConditionIndex"
                                      :index="trueIndex"
                                      v-if="element.action_type == 'condition'"
                                      :actions-length="trueActions.filter((a) => a.action_type != 'condition').length"
                                      :all-actions="trueActions"
                                   />
                                  <div class="step_info mail" :id="`action-box-${element.id}`" :class="{ 'active' : selectedActionIndex === element.id }" @click="selectAction(element.id, 'action', element)" v-if="element.action_type == 'action'">
                                      <i class="fas fa-cog"></i>
                                      <h4>{{ actionTitle(element.type) }}</h4>
                                      <div class="msg_cont">
                                          <div class="action_details">
                                              <label class="info">
                                                  <i class="fas fa-info"></i>
                                                  <div class="quick_info">{{ element.title ? element.title : 'No notes' }}</div>
                                              </label>
                                              <div v-if="element.type == 10">
                                                  <div class="mail_box">
                                                      <div class="msg_desc" :class="{'border-0' : element.email.subject}">
                                                          <p v-html="element.email.subject ? element.email.subject : 'Define Email'"></p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div v-else-if="element.type == 16 || element.type == 17">
                                                  <div v-if="actionName(element)">
                                                      <p class="mb-2">Pipeline name <span class="cloud-chip">{{ actionName(element).pipeline }}</span></p>
                                                      <p v-if="actionName(element).stage && element.type == 16">Stage name <span class="cloud-chip">{{ actionName(element).stage }}</span></p>
                                                      <p v-if="actionName(element).stage && element.type == 17">Current stage to <span class="cloud-chip">{{ actionName(element).stage }}</span></p>
                                                  </div>
                                                  <h4 v-else>Define Action</h4>
                                              </div>
                                              <div v-else-if="element.type == 20 || element.type == 22">
                                                  <div v-if="actionName(element)">
                                                      <p class="mb-2">Task name <span class="cloud-chip">{{ actionName(element).task }}</span></p>
                                                      <p class="task-users" v-if="actionName(element).users.length">Users <span class="cloud-chip" v-for="(user, u) of actionName(element).users" :key="u">{{ user }}</span></p>
                                                  </div>
                                                  <h4 v-else>Define Action</h4>
                                              </div>
                                              <div v-else-if="element.type != 4">
                                                  <template v-if="typeof actionName(element) == 'object' && actionName(element).length">
                                                      <ul>
                                                          <li v-for="(aname, n) of actionName(element)" :key="n">{{ aname }}</li>
                                                      </ul>
                                                  </template>
                                                  <p v-else-if="actionName(element).length" v-html="actionName(element)"></p>
                                                  <h4 v-else>Define Action</h4>
                                                  <span class="attch_icon" v-if="element.sms.media.length">
                                                      <i class="fas fa-paperclip"></i>
                                                  </span>
                                              </div>
                                              <div v-else>
                                                  <ul>
                                                      <li v-for="(email, e) of element.notification.emails" :key="e">{{ email }}</li>
                                                  </ul>
                                                  <div class="mail_box" v-if="element.notification.sending_method == 1 || element.notification.sending_method == 3">
                                                      <div class="msg_desc" :class="{'border-0' : element.notification.subject}">
                                                          <p v-html="element.notification.subject ? element.notification.subject : 'Define Email'"></p>
                                                      </div>
                                                  </div>
                                                  <div class="sms_box" v-if="element.notification.sending_method == 2 || element.notification.sending_method == 3">
                                                      <div class="msg_desc" :class="{'border-0' : element.notification.sms_content}">
                                                          <p v-html="element.notification.sms_content ? element.notification.sms_content : 'Define Message'"></p>
                                                          <span class="attch_icon" v-if="element.notification.sms_media.length">
                                                              <i class="fas fa-paperclip"></i>
                                                          </span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <button type="button" class="remove_btn" @click.stop="handleDeleteAction(element, 'action')"><i class="fas fa-trash-alt"></i></button>
                                  </div>
                                  <div class="step_type" v-if="element.action_type != 'condition'">
                                      <button class="add_new 002" @click.stop="addAction($event)">
                                          <i class="fas fa-plus"></i>
                                      </button>
                                      <div class="add_types">
                                          <h3>Add a next step to your Journey</h3>
                                          <ul>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(trueIndex + 1, 'delay', action.id, 1, trueActions)">
                                                      <i class="far fa-clock"></i>
                                                      <h4>Add Delay</h4>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(trueIndex + 1, 'condition', action.id, 1, trueActions)">
                                                      <i class="fas fa-code-branch"></i>
                                                      <h4>Add Condition</h4>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(trueIndex + 1, 'action', action.id, 1, trueActions)">
                                                      <i class="fas fa-cog"></i>
                                                      <h4>Add Action</h4>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </li>
                          </span>
                      </template>
                  </draggable>
                  <div class="step_type" v-if="trueActions.filter((a) => a.action_type == 'condition').length == 0">
                      <button class="add_new 003" @click.stop="addAction($event)"><i class="fas fa-plus"></i></button>
                      <div class="add_types">
                          <h3>Add a next step to your Journey</h3>
                          <ul>
                              <li>
                                  <div class="card_item" @click.stop="handleAddAction(null, 'delay', action.id, 1, trueActions)">
                                      <i class="far fa-clock"></i>
                                      <h4>Add Delay</h4>
                                  </div>
                              </li>
                              <li>
                                  <div class="card_item" @click.stop="handleAddAction(null, 'condition', action.id, 1, trueActions)">
                                      <i class="fas fa-code-branch"></i>
                                      <h4>Add Condition</h4>
                                  </div>
                              </li>
                              <li>
                                  <div class="card_item" @click.stop="handleAddAction(null, 'action', action.id, 1, trueActions)">
                                      <i class="fas fa-cog"></i>
                                      <h4>Add Action</h4>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
            </li>
            <li>
                  <div class="icons"><i class="fas fa-thumbs-down" style="background: #f2a31e;"></i></div>
                  <draggable v-model="falseActions" :disabled="true" item-key="id" tag="ul" class="journey_list" :animation="200" ghost-class="ghost-card" :group="draggableGroup" :class="{ 'pb-0' : falseActions.length }">
                      <template #item="{element, index: falseIndex}">
                          <span>
                              <div class="delay-action-add" v-if="falseIndex == 0 && element.action_type != 'condition'">
                                  <div class="step_type" v-if="element.action_type != 'condition'">
                                      <button class="add_new 004" @click.stop="addAction($event)">
                                          <i class="fas fa-plus"></i>
                                      </button>
                                      <div class="add_types">
                                          <h3>Add a next step to your Journey</h3>
                                          <ul>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(falseIndex, 'delay', action.id, 0, falseActions)">
                                                      <i class="far fa-clock"></i>
                                                      <h4>Add Delay</h4>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(falseIndex, 'condition', action.id, 0, falseActions)">
                                                      <i class="fas fa-code-branch"></i>
                                                      <h4>Add Condition</h4>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(falseIndex, 'action', action.id, 0, falseActions)">
                                                      <i class="fas fa-cog"></i>
                                                      <h4>Add Action</h4>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                              <li :class="{ branch: element.action_type == 'condition', 'incomplete-action': element.is_finished == 0 }" :id="`step-${element.id}`">
                                  <div class="step_info timer" :id="`action-box-${element.id}`" :class="{ 'active' : selectedDelayIndex === element.id }" @click="selectAction(element.id, 'delay', element)" v-if="element.action_type == 'delay'">
                                      <i class="fas fa-clock"></i>
                                      <h4 v-html="delayTitle(element.delay)"></h4>
                                      <button type="button" class="remove_btn" @click.stop="handleDeleteAction(element, 'delay')"><i class="fas fa-trash-alt"></i></button>
                                  </div>
                                  <condition-component
                                      :action="element"
                                      :delay-title="delayTitle"
                                      :delete-action="deleteAction"
                                      :select-action="selectAction"
                                      :action-Title="actionTitle"
                                      :action-name="actionName"
                                      :add-action="addAction"
                                      :add-new-action="addNewAction"
                                      :selected-trigger-index="selectedTriggerIndex"
                                      :selected-action-index="selectedActionIndex"
                                      :selected-delay-index="selectedDelayIndex"
                                      :selected-condition-index="selectedConditionIndex"
                                      :index="falseIndex"
                                      v-if="element.action_type == 'condition'"
                                      :actions-length="falseActions.filter((a) => a.action_type != 'condition').length"
                                      :all-actions="falseActions"
                                   />
                                  <div class="step_info mail" :id="`action-box-${element.id}`" :class="{ 'active' : selectedActionIndex === element.id }" @click="selectAction(element.id, 'action', element)" v-if="element.action_type == 'action'">
                                      <i class="fas fa-cog"></i>
                                      <h4>{{ actionTitle(element.type) }}</h4>
                                      <div class="msg_cont">
                                          <div class="action_details">
                                              <label class="info">
                                                  <i class="fas fa-info"></i>
                                                  <div class="quick_info">{{ element.title ? element.title : 'No notes' }}</div>
                                              </label>
                                              <div v-if="element.type == 10">
                                                  <div class="mail_box">
                                                      <div class="msg_desc" :class="{'border-0' : element.email.subject}">
                                                          <p v-html="element.email.subject ? element.email.subject : 'Define Email'"></p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div v-else-if="element.type == 16 || element.type == 17">
                                                  <div v-if="actionName(element)">
                                                      <p class="mb-2">Pipeline name <span class="cloud-chip">{{ actionName(element).pipeline }}</span></p>
                                                      <p v-if="actionName(element).stage && element.type == 16">Stage name <span class="cloud-chip">{{ actionName(element).stage }}</span></p>
                                                      <p v-if="actionName(element).stage && element.type == 17">Current stage to <span class="cloud-chip">{{ actionName(element).stage }}</span></p>
                                                  </div>
                                                  <h4 v-else>Define Action</h4>
                                              </div>
                                              <div v-else-if="element.type == 20 || element.type == 22">
                                                  <div v-if="actionName(element)">
                                                      <p class="mb-2">Task name <span class="cloud-chip">{{ actionName(element).task }}</span></p>
                                                      <p class="task-users" v-if="actionName(element).users.length">Users <span class="cloud-chip" v-for="(user, u) of actionName(element).users" :key="u">{{ user }}</span></p>
                                                  </div>
                                                  <h4 v-else>Define Action</h4>
                                              </div>
                                              <div v-else-if="element.type != 4">
                                                  <template v-if="typeof actionName(element) == 'object' && actionName(element).length">
                                                      <ul>
                                                          <li v-for="(aname, n) of actionName(element)" :key="n">{{ aname }}</li>
                                                      </ul>
                                                  </template>
                                                  <p v-else-if="actionName(element).length" v-html="actionName(element)"></p>
                                                  <h4 v-else>Define Action</h4>
                                                  <span class="attch_icon" v-if="element.sms.media.length">
                                                      <i class="fas fa-paperclip"></i>
                                                  </span>
                                              </div>
                                              <div v-else>
                                                  <ul>
                                                      <li v-for="(email, e) of element.notification.emails" :key="e">{{ email }}</li>
                                                  </ul>
                                                  <div class="mail_box" v-if="element.notification.sending_method == 1 || element.notification.sending_method == 3">
                                                      <div class="msg_desc" :class="{'border-0' : element.notification.subject}">
                                                          <p v-html="element.notification.subject ? element.notification.subject : 'Define Email'"></p>
                                                      </div>
                                                  </div>
                                                  <div class="sms_box" v-if="element.notification.sending_method == 2 || element.notification.sending_method == 3">
                                                      <div class="msg_desc" :class="{'border-0' : element.notification.sms_content}">
                                                          <p v-html="element.notification.sms_content ? element.notification.sms_content : 'Define Message'"></p>
                                                          <span class="attch_icon" v-if="element.notification.sms_media.length">
                                                              <i class="fas fa-paperclip"></i>
                                                          </span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <button type="button" class="remove_btn" @click.stop="handleDeleteAction(element, 'action')"><i class="fas fa-trash-alt"></i></button>
                                  </div>
                                  <div class="step_type" v-if="element.action_type != 'condition'">
                                      <button class="add_new 004" @click.stop="addAction($event)">
                                          <i class="fas fa-plus"></i>
                                      </button>
                                      <div class="add_types">
                                          <h3>Add a next step to your Journey</h3>
                                          <ul>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(falseIndex + 1, 'delay', action.id, 0, falseActions)">
                                                      <i class="far fa-clock"></i>
                                                      <h4>Add Delay</h4>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(falseIndex + 1, 'condition', action.id, 0, falseActions)">
                                                      <i class="fas fa-code-branch"></i>
                                                      <h4>Add Condition</h4>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div class="card_item" @click.stop="handleAddAction(falseIndex + 1, 'action', action.id, 0, falseActions)">
                                                      <i class="fas fa-cog"></i>
                                                      <h4>Add Action</h4>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </li>
                          </span>
                      </template>
                  </draggable>
                  <div class="step_type" v-if="falseActions.filter((a) => a.action_type == 'condition').length == 0">
                      <button class="add_new 005" @click.stop="addAction($event)"><i class="fas fa-plus"></i></button>
                      <div class="add_types">
                          <h3>Add a next step to your Journey</h3>
                          <ul>
                              <li>
                                  <div class="card_item" @click.stop="handleAddAction(null, 'delay', action.id, 0, falseActions)">
                                      <i class="far fa-clock"></i>
                                      <h4>Add Delay</h4>
                                  </div>
                              </li>
                              <li>
                                  <div class="card_item" @click.stop="handleAddAction(null, 'condition', action.id, 0, falseActions)">
                                      <i class="fas fa-code-branch"></i>
                                      <h4>Add Condition</h4>
                                  </div>
                              </li>
                              <li>
                                  <div class="card_item" @click.stop="handleAddAction(null, 'action', action.id, 0, falseActions)">
                                      <i class="fas fa-cog"></i>
                                      <h4>Add Action</h4>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import { uuid } from 'vue-uuid'
    import { mapState, mapActions } from 'vuex'

    import Draggable from 'vuedraggable'

    export default {
        name: 'Condition '+uuid.v4(),

        data () {
            return {
                draggableGroup: 'draggable-'+uuid.v4(),
                trueActions: [],
                falseActions: [],
            };
        },

        props: {
            action: Object,
            delayTitle: Function,
            deleteAction: Function,
            selectAction: Function,
            actionTitle: Function,
            actionName: Function,
            addAction: Function,
            addNewAction: Function,
            selectedTriggerIndex: Number,
            selectedActionIndex: Number,
            selectedDelayIndex: Number,
            selectedConditionIndex: Number,
            index: Number,
            actionsLength: Number,
            allActions: [Object, Array]
        },

        components: {
            Draggable,
        },

        computed: mapState({
            tags: state => state.tagModule.tags,
            statuses: state => state.contactModule.statusList,
            groups: state => state.groupModule.groups,
        }),

        mounted () {
            const vm = this;

            vm.getConditionActions(vm.action.id).then((resp) => {
                if (resp) {
                    vm.trueActions  = resp.true_actions ? resp.true_actions : [];
                    vm.falseActions = resp.false_actions ? resp.false_actions : [];
                }
            });
        },

        methods: {
            ...mapActions({
                getConditionActions: 'journeyModule/getConditionActions',
            }),

            refreshConditionActions () {
                const vm = this;

                vm.getConditionActions(vm.action.id).then((resp) => {
                    if (resp) {
                        vm.trueActions  = resp.true_actions ? resp.true_actions : [];
                        vm.falseActions = resp.false_actions ? resp.false_actions : [];
                    }
                });
            },

            handleAddAction (order, action_type, parent_id = 0, with_true_condition = 0, actions) {
                const vm = this;


                vm.addNewAction(order, action_type, parent_id, with_true_condition, actions).then((result) => {
                    if (result) {
                        vm.refreshConditionActions();
                    }
                });
            },

            handleDeleteAction (action, type) {
                const vm = this;

                vm.deleteAction(action, type).then((result) => {
                    vm.refreshConditionActions();

                    if (vm.$parent.$parent && vm.$parent.$parent.$parent && vm.$parent.$parent.$parent.refreshConditionActions) {
                        vm.$parent.$parent.$parent.refreshConditionActions();
                    } else if (vm.$parent.$parent.refreshConditionActions) {
                        vm.$parent.$parent.refreshConditionActions();
                    }
                });
            },

            conditionTitle (condition) {
                const vm = this;
                let title = 'Set Condition';

                if (condition.rules[0].type == 1) {
                    const tags = vm.tags.filter((tag) => condition.rules[0].tag == tag.id);
                    let tagNames = '<ul>';

                    tags.forEach((tag, t) => {
                        tagNames += `<li>${tag.name}</li>`;
                    });

                    title = `Has tag ${tagNames}</ul>`
                } else if (condition.rules[0].type == 2) {
                    const groups = vm.groups.filter((group) => condition.rules[0].group == group.id);
                    let groupNames = '<ul>';

                    groups.forEach((group, t) => {
                        groupNames += `<li>${group.name}</li>`;
                    });

                    title = `Has group ${groupNames}</ul>`
                } else if (condition.rules[0].type == 3) {
                    const tabs = vm.statuses.filter((status) => condition.rules[0].tab == status.value);
                    let tabNames = '<ul>';

                    tabs.forEach((tab, t) => {
                        tabNames += `<li>${tab.title}</li>`;
                    });

                    title = `Has tab ${tabNames}</ul>`
                }

                if (condition.rules.length > 1) {
                    title += ` ${condition.rule_type ? 'AND' : 'OR'} +${condition.rules.length - 1} condition`;
                }

                return title;
            },
        },
    }
</script>

<style scoped>
    .branch-add-action {
        position: relative;
        padding-bottom: 70px;
        z-index: +99;
    }

    .branch-add-action:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        transform: translateX(-50%);
        border-left: 1px solid #e9e9e9;
        z-index: 0;
    }

    .journey_wpr .branch-add-action .step_type {
        top: 0;
    }

    .delay-action-add {
        position: relative;
        padding-top: 55px;
    }

    :deep(.branch_info h4 ul) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
        margin: 5px 0px;
    }

    :deep(.branch_info h4 ul li) {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        background: #e4e4e4;
        padding: 4px 10px;
        border-radius: 10px;
    }
</style>
